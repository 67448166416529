import { faSquareRss } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import Head from "../components/security/Head"
import Reporting from "../components/security/Reporting"
import highlightCode from "../components/utils/HighlightCode"
import { htmlDecode, isUrl } from "../components/utils/IsUrl"

// props
interface Props {
  data: {
    page: {
      title: string
      slug: string
      references?: Array<string>
      severity: string
      fields: {
        publishedAt: string
      }
      description: {
        childMarkdownRemark: {
          html: string
        }
      }
      history?: Array<string>
      mitigation: {
        childMarkdownRemark: {
          html: string
        }
      }
      productsAndVersions: {
        childMarkdownRemark: {
          html: string
        }
      }
      credit?: {
        childMarkdownRemark: {
          html: string
        }
      }
    }
  }
}

// markup
const CveTemplate = (props: Props) => {
  React.useEffect(() => {
    highlightCode()
  })
  return (
    <Layout
      className={"cve"}
      seo={{ title: props.data.page.title, hasNoSpring: true }}
    >
      <div className="container">
        <div className="pb-5 cve-content">
          <Head />

          <div className="columns pt-6">
            <div className="column content is-8">
              <h1 className="is-size-3 has-text-weight-semibold">
                {props.data.page.title}
              </h1>
              <div className="meta py-4">
                {props.data.page.severity?.toUpperCase()} |{" "}
                {props.data.page.fields.publishedAt.toUpperCase()} |{" "}
                {props.data.page.slug.toUpperCase()}
              </div>
              <div className="py-3">
                <h2 className="is-size-4">Description</h2>
                <HTMLContent
                  content={props.data.page.description.childMarkdownRemark.html}
                  className={"markdown"}
                ></HTMLContent>
              </div>
              <div className="py-3">
                <h2 className="is-size-4">
                  Affected Spring Products and Versions
                </h2>
                <HTMLContent
                  content={
                    props.data.page.productsAndVersions.childMarkdownRemark.html
                  }
                  className={"markdown"}
                ></HTMLContent>
              </div>
              <div className="py-3">
                <h2 className="is-size-4">Mitigation</h2>
                <HTMLContent
                  content={props.data.page.mitigation.childMarkdownRemark.html}
                  className={"markdown"}
                ></HTMLContent>
              </div>
              {props.data.page.credit && (
                <div className="py-3">
                  <h2 className="is-size-4">Credit</h2>
                  <HTMLContent
                    content={props.data.page.credit.childMarkdownRemark.html}
                    className={"markdown"}
                  ></HTMLContent>
                </div>
              )}
              {props.data.page.references && (
                <div className="py-3">
                  <h2 className="is-size-4">References</h2>
                  <ul>
                    {props.data.page.references.map(
                      (ref: string, i: number) => (
                        <>
                          <li key={`ref${i}`}>
                            {isUrl(ref.trim()) ? (
                              <a key={`a${i}`} href={ref}>
                                {ref}
                              </a>
                            ) : (
                              <HTMLContent
                                key={`span${i}`}
                                content={htmlDecode(ref)}
                              ></HTMLContent>
                            )}
                          </li>
                        </>
                      )
                    )}
                  </ul>
                </div>
              )}
              {props.data.page.history && (
                <div className="py-3">
                  <h2 className="is-size-4">History</h2>
                  <ul>
                    {props.data.page.history.map((hist: string, i: number) => (
                      <li key={`hist${i}`}>{hist}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="column is-4">
              <Reporting />
            </div>
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default CveTemplate

// graphQL queries
export const pageQuery = graphql`
  query CveTemplate($id: String!) {
    page: contentfulCvEs(id: { eq: $id }) {
      title
      slug
      references
      severity
      fields {
        publishedAt(formatString: "MMMM DD, YYYY")
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      history
      mitigation {
        childMarkdownRemark {
          html
        }
      }
      productsAndVersions {
        childMarkdownRemark {
          html
        }
      }
      credit {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
