import { decode } from "html-entities"

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/
const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/
const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/

export const isUrl = (str: string): boolean => {
  if (typeof str !== "string") {
    return false
  }
  const match = str.match(protocolAndDomainRE)
  if (!match) {
    return false
  }
  const everythingAfterProtocol = match[1]
  if (!everythingAfterProtocol) {
    return false
  }
  if (
    localhostDomainRE.test(everythingAfterProtocol) ||
    nonLocalhostDomainRE.test(everythingAfterProtocol)
  ) {
    return true
  }
  return false
}

export const htmlDecode = (str: string): string => {
  return decode(str, { level: "html5" })
}
