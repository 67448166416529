import React from "react"

const Reporting = () => (
  <div className="report links-animate has-background-light">
    <h2 className="is-size-4 mb-2">Reporting a vulnerability</h2>
    <p>
      The VMware Security Response team provides a single point of contact for
      the reporting of security vulnerabilities in VMware Tanzu products and
      coordinates the process of investigating any reported vulnerabilities.
    </p>
    <p>
      To report a security vulnerability in a VMware service or product please
      refer to the VMware{" "}
      <a href="https://www.vmware.com/support/policies/security_response.html">
        Security Response Policy
      </a>
      .
    </p>
  </div>
)
export default Reporting
